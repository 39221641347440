<template>
  <KTCodePreview v-bind:title="'Thông tin phiếu kiểm tồn kho'">
    <template v-slot:toolbar></template>
    <template v-slot:preview>
      <div>
        <b-tabs pills v-model="tabIndex" card>
          <b-tab title="Thông tin" active :title-link-class="linkClass(0)">
            <div>
              <b-row class="mb-5">
                <b-col>
                  <b-list-group>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3">Mã phiếu:</b-col>
                        <b-col>{{ mainModel.productStockCheck.code }}</b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3">Cửa hàng:</b-col>
                        <b-col>{{
                          mainModel.productStockCheck.storeName
                        }}</b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3"
                          >Trạng thái:</b-col
                        >
                        <b-col>
                          <StatusLabel
                            :status="mainModel.productStockCheck.status || 1"
                            :statusName="mainModel.productStockCheck.statusName"
                          />
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3"
                          >Người tạo:</b-col
                        >
                        <b-col>{{
                          mainModel.productStockCheck.employeeName
                        }}</b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3"
                          >Loại sản phẩm:</b-col
                        >
                        <b-col>{{
                          productTypeName[
                            mainModel.productStockCheck.productType
                          ] || 'Tất cả'
                        }}</b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                      <b-row>
                        <b-col class="font-weight-bold" md="3">Ngày tạo:</b-col>
                        <b-col>{{
                          formatDate(mainModel.productStockCheck.createdAt)
                        }}</b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group></b-col
                >
                <b-col>
                  <div class="note">
                    <b-form-textarea
                      v-model="mainModel.productStockCheck.note"
                      id="textarea-small"
                      size="sm"
                      placeholder="Nhập ghi chú"
                    ></b-form-textarea>
                  </div>
                </b-col>
              </b-row>
              <hr
                class="hr-text"
                data-content="Thông tin sản phẩm"
                style="font-weight: 600"
              />
              <InventoryCheckTable
                @fetchData="fetchDataByQuery"
                :mainModel="mainModel"
              />
            </div>
          </b-tab>
          <b-tab
            title="Kiểm tồn"
            :title-link-class="linkClass(1)"
            v-if="
              mainModel.productStockCheck.status ===
              PRODUCT_STOCK_CHECK_STATUS.NEW
            "
          >
            <InventoryCheckTable
              @fetchData="fetchDataByQuery"
              :mainModel="mainModel"
            />
          </b-tab>
          <template
            v-if="
              mainModel.productStockCheck.status !==
                PRODUCT_STOCK_CHECK_STATUS.NEW &&
              mainModel.productStockCheck.status !==
                PRODUCT_STOCK_CHECK_STATUS.COMFIRM &&
              checkPermission('DEBT_STOCK_VIEW')
            "
          >
            <b-tab title="Cân tồn & Truy thu" :title-link-class="linkClass(1)">
              <StockDebt
                @change="handleChildTabChange"
                @fetchProductCheckStock="fetchData"
                :inventoryCheckData="mainModel"
              />
            </b-tab>
            <b-tab title="Lịch sử" :title-link-class="linkClass(2)">
              <StockDebtHistory :inventoryCheckData="mainModel" />
            </b-tab>
          </template>
        </b-tabs>
      </div>
    </template>
  </KTCodePreview>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { formatDate } from '@/utils/common';
import StatusLabel from '@/view/components/inventoryCheck/StatusLabel.vue';
import InventoryCheckTable from '@/view/components/inventoryCheck/InventoryCheckTable.vue';
import StockDebt from '@/view/components/inventoryCheck/StockDebt.vue';
import StockDebtHistory from '@/view/components/inventoryCheck/StockDebtHistory.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { PRODUCT_STOCK_CHECK_STATUS } from '@/utils/enum';
import localData from '@/utils/saveDataToLocal';

export default {
  data() {
    return {
      PRODUCT_STOCK_CHECK_STATUS,
      totalItem: 0,
      filteredOptionsProduct: [],
      productTypeName: {
        1: 'Sản phẩm',
        2: 'Sản phẩm theo IMEI',
      },
      mainModel: {
        productStockCheck: {},
        totalPage: 0,
        totalItem: 0,
        items: [],
      },
      statusCheck: null, 
      tabIndex: 0,
      searchProduct: '',
      searchProductImei: '',
      productStockCheckId: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách phiếu kiểm tồn kho', route: '/inventory-check' },
      { title: 'Kiểm tra tồn kho' },
    ]);
    this.fetchData();
  },
  created() {},
  computed: {},
  methods: {
    formatDate,
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    handleChildTabChange() {
      this.mainModel = { ...this.mainModel };
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light', 'font-weight-bold'];
      } else {
        return ['bg-light', 'text-dark', 'font-weight-bold'];
      }
    },
    fetchDataByQuery(searchProduct, searchProductImei, status) {
      if (searchProduct || searchProductImei) {
        this.$route.query.pageNum = 1;
      }
      this.searchProduct = searchProduct;
      this.searchProductImei = searchProductImei;
      this.statusCheck = status;
      this.fetchData();
    },
    fetchData: function () {
      const id = Number(this.$route.query.id) || null;
      const code = this.$route.query.code || null;
      this.page = this.$route.query.pageNum || 1;
      const params = {
        page: this.page,
        code,
        id,
        pageSize: 10,
        status: this.statusCheck,
        searchProduct: this.searchProduct,
        searchProductImei: this.searchProductImei,
      };
      ApiService.query('productStockCheck/getProductStockCheckItems', {
        params,
      }).then(({ data }) => {
        this.mainModel = {
          productStockCheck: data.data.detail,
          totalPage: data.data.totalPage,
          totalItem: data.data.totalRow,
          items: data.data.items,
        };
      });
    },
  },
  components: {
    StatusLabel,
    KTCodePreview,
    InventoryCheckTable,
    StockDebt,
    StockDebtHistory,
  },
};
</script>

<style>
.tab-status {
  background-color: ghostwhite;
}

.tab-status .nav .nav-link {
  font-weight: bold;
  font-size: 1.1rem;
  color: black;
}

.tab-status .nav .nav-link:hover {
  color: slateblue;
}

.tab-status .nav .nav-link.active {
  color: slateblue;
}

.input-style {
  border: 1px solid #ced4da;
}

.stock-info .card-body {
  padding: 1rem;
}

.form-group label {
  font-weight: 500;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.icon-check {
  color: #1bc5bd !important;
  font-size: 25px;
}

.btn-update .btn.btn-sm i,
.btn-update .btn i {
  padding-right: 0;
}

.note textarea.form-control,
.note {
  height: 100%;
}

.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  font-weight: 500;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 5px 8px 5px 8px;
}
</style>
