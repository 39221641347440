<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-table bordered :items="mainModel.products" outlined :fields="fieldProducts">
          <template v-slot:cell(productName)="data">
            <b v-if="data.item.barCode" v-text="data.item.barCode"></b>
            <p v-if="data.item.productCode" class="productCode my-1">
              <span v-text="data.item.productCode"></span>
            </p>
            <p class="m-0">{{ data.item.productName }}</p>
            <p v-if="data.item.productImei" class="text-sm text-primary m-0">
              IMEI: {{ data.item.productImei }}
            </p>
          </template>
          <template v-slot:cell(quantity)="data">{{
            getQuantityProdct(data.item)
          }}</template>
          <template v-slot:cell(productPrice)="data">
            {{ formatPrice(data.item.productPrice) }}
          </template>
          <template v-slot:cell(totalAmout)="data">
            {{ formatPrice(getTotalAmoutProduct(data.item)) }}
          </template>
          <template v-slot:cell(debt)="data">
            {{ formatPrice(data.item.debt) }}
          </template>
          <template v-slot:cell(actions)="row">
            <div v-if="
              inventoryCheckStatus !== PRODUCT_STOCK_CHECK_STATUS.DONE && checkPermission('STOCK_CHECK_UPDATE')
            " class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i style="font-size: 1rem; padding-right: 0px" class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item v-if="getQuantityProdct(row.item) < 0" @click="showStockDebtModal(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Truy thu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="showInventoryStockEditModal(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <hr class="hr-text" data-content="Danh sách nhân viên truy thu trừ lương" style="font-weight: 600" />
        <div>
          <div v-if="inventoryCheckStatus !== PRODUCT_STOCK_CHECK_STATUS.DONE" class="my-3">
            <Autosuggest :disabled="!checkPermission('STOCK_CHECK_CONFIRM')" :model="searchEmployee"
              :suggestions="filteredEmployeeOptions" @select="onSelected" :limit="10" @change="onInputChange">
              <template #custom="{ suggestion }">
                <div>
                  <span class="text-primary">{{ suggestion.item.code }}</span>
                  <span class="text-dark font-weight-bold">
                    - {{ suggestion.item.fullName }} -
                  </span>
                  <span>{{ suggestion.item.jobTitleName }}</span>
                </div>
              </template>
              <template slot="after-section">
                <div class="p-2 text-center" v-if="!filteredEmployeeOptions.length">
                  <div v-if="!employeeLoading">
                    <img src="../../../assets/image/no-results.png" />
                    <b class="ml-2">Không có kết quả</b>
                  </div>
                  <b-spinner label="Spinning" v-if="employeeLoading"></b-spinner>
                </div>
              </template>
            </Autosuggest>
          </div>
          <div>
            <b-table class="table-bordered table-hover" ref="myTable" :fields="fieldDetail"
              :items="mainModel.stockDebtItems" :per-page="10" :current-page="currentPageDetail"
              id="stock-dept-table-detail">
              <template v-slot:cell(employee)="row">
                <div>
                  <b-row>
                    <b-col md="5" class="font-weight-bold font-size-sm text-primary"><span></span>{{ row.item.employeeCode
                    }}
                    </b-col>
                    <b-col class="font-size-sm">{{
                      row.item.jobTitleName
                    }}</b-col>
                  </b-row>
                  <hr class="my-1" />
                  <p class="font-size-lg font-weight-bold text-dark mb-0 mt-2">
                    {{ row.item.employeeName }}
                  </p>
                </div>
              </template>
              <template v-slot:cell(amount)="row">
                <b-input-group>
                  <template #prepend>
                    <b-dropdown :disabled="row.item.paid > 0" @change="checkvalidation(row.item)" :text="
                      row.item.amountType === AMOUNT_TYPE.MONEY
                        ? '$'
                        : row.item.amountType === AMOUNT_TYPE.PERCENT
                          ? '%'
                          : ''
                    " size="sm" variant="outline-primary">
                      <b-dropdown-item @click="onClickAmountType(AMOUNT_TYPE.MONEY, row)">{{ '$' }}</b-dropdown-item>
                      <b-dropdown-item @click="onClickAmountType(AMOUNT_TYPE.PERCENT, row)">{{ '%' }}</b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <b-form-input :disabled="row.item.paid > 0" size="sm" v-model="row.item.amount" placeholder="Giá trị"
                    type="text" :min="1" @change="onAmoutInputChange(row.item)" v-mask="mask"
                    class="text-input-size amount-input"></b-form-input>
                  <b-form-invalid-feedback :state="validateAmout">{{
                    messageErrorAmount
                  }}</b-form-invalid-feedback>
                </b-input-group>
              </template>
              <template v-slot:cell(paid)="row">
                <b-row>
                  <b-col> {{ formatPrice(calPaidAmount(row.item)) }}</b-col>
                  <b-col>
                    <div v-if="
                      inventoryCheckStatus !==
                      PRODUCT_STOCK_CHECK_STATUS.DONE
                    " @click="row.toggleDetails" v-b-tooltip.hover title="Thêm mới">
                      <i v-if="row.detailsShowing" class="text-primary fa fa-chevron-up">
                      </i>
                      <i v-else class="text-primary fa fa-plus"> </i>
                    </div>
                  </b-col>
                </b-row>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col>
                      <label>Thanh toán thêm:</label>
                      <b-form-input type="number" v-model="row.item.paidAdd"
                        placeholder="Nhập số tiền thanh toán" trim>{{ formatPrice(row.item.paidAdd) }}</b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-textarea class="h-100" id="textarea-state" v-model="row.item.paymentDescription"
                        placeholder="Nhập mô tả thanh toán"></b-form-textarea>
                    </b-col>
                  </b-row>
                  <b-button variant="primary" size="sm" @click="row.toggleDetails">Lưu</b-button>
                </b-card>
              </template>
              <template v-slot:cell(debt)="row">
                {{ formatPrice(row.item.debt) }}
              </template>
              <template v-slot:cell(actions)="row">
                <span v-if="
                  inventoryCheckStatus !==
                  PRODUCT_STOCK_CHECK_STATUS.DONE
                " style="color: #3f4254; font-size: 12px">
                  <i @click="showDeleteAlert(row.item)" v-b-tooltip title="Xóa" style="font-size: 1rem; color: #d33"
                    class="flaticon2-rubbish-bin-delete-button"></i>
                </span>
              </template>
              <template v-slot:cell(debtType)="row">
                <b-dropdown @change="checkvalidation(row.item)" :text="
                  row.item.debtType === DEBT_TYPE.DEBT
                    ? 'Công nợ'
                    : row.item.debtType === DEBT_TYPE.SUPPORT
                      ? 'Hỗ trợ'
                      : ''
                " size="sm" variant="outline-primary">
                  <b-dropdown-item @click="onClickDebtType(DEBT_TYPE.DEBT, row)">Công nợ</b-dropdown-item>
                  <b-dropdown-item @click="onClickDebtType(DEBT_TYPE.SUPPORT, row)">Hỗ trợ</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
      <b-col md="3">
        <StockDebtPayment :stockDebtData="mainModel" :finalAmount="finalAmount" v-on:receive-payment="onUpdatePayment"
          :disabled="!checkPermission('DEBT_STOCK_UPDATE') || inventoryCheckStatus === PRODUCT_STOCK_CHECK_STATUS.DONE"
          v-on:receive-payment-mul="onUpdatePaymentMul" v-if="renderPayment" />
        <div class="d-flex flex-column justify-content-between">
          <b-row class="total"><b-col>Tổng truy thu:</b-col><b-col>{{ formatPrice(getTotalAmountDebt) }}</b-col>
          </b-row>
          <b-row class="total"><b-col>Tổng đã thanh toán:</b-col>
            <b-col>{{ formatPrice(totalAmountPaid) }}</b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              {{ gettitleFinal() }}:
            </b-col>
            <b-col md="6">
              {{ formatPrice(Math.abs(remainAmount)) }}
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-button v-if="inventoryCheckStatus !== PRODUCT_STOCK_CHECK_STATUS.DONE" @click="submit()" size="sm"
      variant="primary" class="font-weight-bolder mr-3">
      Lưu
    </b-button>
    <AddStockDebtModal v-on:addStockDebt="fetchProductCheckStock" :productStockCheckItem="productStockCheckItem"
      ref="add-stock-debt-modal" />
    <InventoryEditModal v-on:fetchProductCheckStock="fetchProductCheckStock"
      :productStockCheckItem="productStockCheckItem" ref="edit-inventory-stock-modal" />
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { AMOUNT_TYPE, PRODUCT_STOCK_CHECK_STATUS } from '@/utils/enum';
import { v4 as uuidv4 } from 'uuid';
import localData from '@/utils/saveDataToLocal';

import {
  currencyMask,
  unMaskPrice,
  formatPrice,
  cloneDeep,
} from '@/utils/common';
import AddStockDebtModal from '@/view/components/inventoryCheck/AddStockDebtModal.vue';
import StockDebtPayment from '@/view/components/inventoryCheck/StockDebtPayment.vue';
import InventoryEditModal from '@/view/components/inventoryCheck/InventoryEditModal.vue';
import { PRODUCT_TYPE, PAYMENT_TYPE } from '@/utils/enum';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import sumBy from 'lodash/sumBy';


export default {
  props: {
    inventoryCheckData: {
      type: Object,
    },
  },
  watch: {
    $props: {
      handler(newProps) {
        const { id } = newProps.inventoryCheckData.productStockCheck
        if (id) {
          this.mainModel.productStockCheckId = id
          this.fetchDeviationStockProducts()
          this.fetchStockDebt();
        }
      
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      PRODUCT_STOCK_CHECK_STATUS,
      paid: 0,
      DEBT_TYPE: {
        DEBT: 1,
        SUPPORT: 2,
      },
      renderPayment: true,
      inventoryCheckStatus: null,
      messageErrorImei: '',
      productType: PRODUCT_TYPE,
      currentPageDetail: 1,
      productStockCheckItem: {},
      searchEmployee: '',
      mask: currencyMask,
      filteredEmployeeOptions: [],
      productCheckStockItem: {},
      fieldProducts: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { width: '25%' },
          thClass: 'text-center',
        },
        {
          key: 'productPrice',
          label: 'Đơn giá',
          thClass: 'text-center align-middle',
          tdClass: 'text-right align-middle',
          thStyle: { width: '10%' },
        },
        {
          key: 'quantity',
          label: 'Lệch',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
          thStyle: { width: '8%' },
        },
        {
          key: 'totalAmout',
          label: 'Thành tiền',
          thClass: 'text-center align-middle',
          tdClass: 'text-right align-middle',
          thStyle: { width: '12%' },
        },
        {
          key: 'debt',
          label: 'Truy thu',
          thClass: 'text-center align-middle',
          tdClass: 'text-right align-middle',
          thStyle: { width: '15%' },
        },
        {
          key: 'statusName',
          label: 'Tình trạng',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          thStyle: { width: '15%' },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center align-middle',
          thStyle: { width: '5%' },
        },
      ],
      optionsEmployees: [],
      employees: [],
      error: false,
      messageErrorAmount: '',
      AMOUNT_TYPE: AMOUNT_TYPE,
      selectedEmployee: [],
      employeeLoading: false,
      totalMinusSalary: 0,
      transferAmount: 0,
      totalAmountPaid: 0,
      mainModel: {
        moneyPaid: 0,
        moneyTransfered: 0,
        productStockCheckId: null,
        prpoductStockCheckCode: null,
        storeId: null,
        storeName: '',
        stockDebtItems: [],
        products: [],
        code: null,
        paymentInfo: {
          //tiền mặt
          cashAmount: 0,
          cashAccountantName: '',
          cashAccountId: null,
          //chuyển khoản
          transferAmount: 0,
          transferAccountantName: '',
          transferAccountantId: null,
          transferReferenceCode: '',
        },
        transfer: [],
        cash: [],
      },
      fieldDetail: [
        {
          key: 'employee',
          label: 'Nhân viên',
          sortable: false,
          thClass: 'thtable text-center align-middle',
          tdClass: 'align-middle text-left',
          thStyle: {
            width: '28%',
          },
        },
        {
          key: 'amount',
          label: 'Giá trị',
          thClass: 'thtable text-center',
          tdClass: 'align-middle text-center',
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          tdClass: 'align-middle text-right',
          thClass: 'thtable text-center',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'paid',
          label: 'Thanh toán',
          tdClass: 'align-middle text-right',
          thClass: 'thtable text-center',
          thStyle: {
            width: '17%',
          },
        },
        {
          key: 'timesPaid',
          label: 'Lần trả',
          tdClass: 'align-middle text-center',
          thClass: 'thtable text-center',
          thStyle: {
            width: '7%',
          },
        },
        {
          key: 'debtType',
          label: 'Loại',
          tdClass: 'align-middle text-center',
          thClass: 'thtable text-center',
          thStyle: {
            width: '8%',
          },
        },
        {
          key: 'actions',
          tdClass: 'align-middle text-center',
          label: '',
          thStyle: {
            width: '5%',
          },
        },
      ],
      validateAmout: true,
      validateImei: false,
    };
  },
  components: {
    AddStockDebtModal,
    InventoryEditModal,
    StockDebtPayment,
    Autosuggest
  },
  methods: {
    formatPrice,
    submit() {
      if (this.inventoryCheckStatus === PRODUCT_STOCK_CHECK_STATUS.STOCK_DIFF) {
        this.showConfirmAlert()
      } else {
        this.upsertStockDebt()
      }
    },
    showConfirmAlert: function () {
      Swal.fire({
        title: `Xác nhận cân tồn và truy thu !`,
        text: `Xác nhận sẽ tự động cân tồn nếu có lệch kho và sẽ tiến hành truy thu nếu có !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xác nhận',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.upsertStockDebt()
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onUpdatePayment(paymentInfo) {
      this.mainModel.paymentInfo = cloneDeep(paymentInfo);
    },
    onUpdatePaymentMul(items = [], type) {
      const payments = items.reduce((result, current) => {
        result.push({
          ...current,
          paymentAmount: unMaskPrice(current.paymentAmount),
        });
        return result;
      }, []);
      switch (type) {
        case PAYMENT_TYPE.TRANSFER: {
          this.mainModel.transfer = payments;
          break;
        }
        case PAYMENT_TYPE.CASH: {
          this.mainModel.cash = payments;
          break;
        }
      }
    },
    renderTable() {
      this.$nextTick(() => {
        this.$refs.myTable.refresh();
      });
    },
    getTotalAmoutProduct(productCheckItem) {
      return (
        Math.abs(this.getQuantityProdct(productCheckItem)) *
        productCheckItem.productPrice
      );
    },
    showStockDebtModal(productCheckStockItem) {
      this.productStockCheckItem = productCheckStockItem;
      this.$refs['add-stock-debt-modal'].showModal();
    },
    showInventoryStockEditModal(productCheckStockItem) {
      this.productStockCheckItem = productCheckStockItem;
      this.$refs['edit-inventory-stock-modal'].showModal();
    },
    fetchProductCheckStock() {
      this.$emit('fetchProductCheckStock');
    },
    onSelected(option) {
      this.searchEmployee = '';
      this.selectedEmployee = option.item;
      if (this.getTotalAmountDebt === 0) {
        makeToastFaile('Chưa tạo truy thu !');
        return;
      }
      if (this.getTotalAmountDebt === this.getTotalPaid) {
        makeToastFaile('Đã thanh toán đủ');
        return;
      }
      this.addStockDebtItem();
      this.optionsEmployees = [];
      this.filteredEmployeeOptions = [];
    },
    getQuantityProdct(product) {
      return (
        product.realStockQuantity -
        (product.totalQuantityInStock +
          product.totalQuantityHolding +
          product.totalQuantityWarranty)
      );
    },
    onClickAmountType(type, stockDebtItem) {
      stockDebtItem.item.amountType = type;
      stockDebtItem.item.amount = 0;
      stockDebtItem.item.totalAmount = 0;
      this.checkvalidation(stockDebtItem);
    },
    onClickDebtType(type, stockDebtItem) {
      stockDebtItem.item.debtType = type;
    },
    checkvalidation(stockDebtItem) {
      if (
        stockDebtItem.amountType == AMOUNT_TYPE.PERCENT &&
        unMaskPrice(stockDebtItem.amount) > 100
      ) {
        this.validateAmout = false;
        this.messageErrorAmount = 'Giá trị vượt mức 100%!';
      } else {
        this.validateAmout = true;
        this.unMaskPrice = '';
      }
      this.error = false;
    },
    onAmoutInputChange(stockDebtItem) {
      this.checkvalidation(stockDebtItem);

      if (stockDebtItem.amountType === AMOUNT_TYPE.PERCENT) {
        stockDebtItem.totalAmount =
          (stockDebtItem.amount * this.getTotalAmountDebt) / 100;
      } else {
        stockDebtItem.totalAmount = stockDebtItem.amount;
      }
    },
    fetchEmployee: function () {
      this.onLoading = true;
      const params = {
        page: 1,
        pageSize: 10,
        fullName: this.searchEmployee,
      };

      const paramQuery = {
        params,
      };

      ApiService.query('employees', paramQuery).then((response) => {
        this.employees = response.data.data.employees;
        this.optionsEmployees = this.employees;
        this.filteredEmployeeOptions = this.employees;
        this.employeeLoading = false;
        this.onLoading = false;
      });
    },
    onInputChange(text) {
      this.optionsEmployees = [];
      this.filteredEmployeeOptions = [];
      if (!text) {
        text = '';
        this.employeeLoading = false;
        return;
      }
      this.employeeLoading = true;
      (this.searchEmployee = text.trim()), this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      if (this.searchEmployee) {
        this.fetchEmployee();
      }
    }, TIME_TRIGGER),
    addStockDebtItem() {
      if (this.mainModel.stockDebtItems.length > 0) {
        for (const stockDebtItem of this.mainModel.stockDebtItems) {
          if (!stockDebtItem.employeeCode) {
            makeToastFaile('Vui lòng nhập chọn nhân viên!');
            return;
          }
          if (!stockDebtItem.amountType && !stockDebtItem.amount) {
            makeToastFaile('Vui lòng chọn nhập giá trị!');
            return;
          }

          this.optionsEmployees = [];
        }
      }
      this.mainModel.stockDebtItems.push({
        id: uuidv4(),
        employeeCode: this.selectedEmployee.code,
        employeeId: this.selectedEmployee.id,
        employeeName: this.selectedEmployee.fullName,
        jobTitleName: this.selectedEmployee.jobTitleName,
        jobTitleId: this.selectedEmployee.jobTitleId,
        totalAmount: 0,
        amountType: 1,
        paidAdd: 0,
        amount: 0,
        debtType: 1,
        paid: 0,
        timesPaid: 0,
      });
      this.renderTable();
    },
    upsertStockDebt: function (mode = 'save') {
      const stockDebtItems = this.mainModel.stockDebtItems.map((element) => {
        return { ...element, paid: this.calPaidAmount(element) }
      })
      const data = {
        productStockCheckId: this.mainModel.productStockCheckId,
        stockDebtItems,
        products: this.mainModel.products,
        storeId: this.mainModel.storeId,
        storeName: this.mainModel.storeName,
        productStockCheckCode: this.mainModel.code,
        transfer: this.mainModel.transfer,
        cash: this.mainModel.cash,
        //tien mat
        cashAmount: this.calcPaymentsAmount(this.mainModel.cash),
        cashAccountId: this.mainModel.cash[0].paymentRefId,
        cashAccountCode: null,
        //chuyen khoan
        transferAmount: this.calcPaymentsAmount(this.mainModel.transfer),
        transferAccountId: this.mainModel.transfer[0].paymentRefId,
        transferAccountCode: this.mainModel.transfer[0].paymentCode,
        transferReferenceCode: this.mainModel.transfer[0].paymentCode,
      };

      ApiService.post('/stockDebt/upsert', data)
        .then(({ data }) => {
          makeToastSuccess(data.message);

          if (mode === 'save-print') {
            this.$router.push({
              name: 'print-inventory-check',
              query: { id: this.mainModel.productStockCheckId },
            });
          } else {
            this.fetchProductCheckStock()
          }

        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    calcPaymentsAmount(items) {
      return items.reduce((result, item) => {
        const unMask = unMaskPrice(item.paymentAmount) || 0;
        return result + Number(unMask);
      }, 0);
    },
    fetchDeviationStockProducts: function () {
      const id = Number(this.mainModel.productStockCheckId) || null;
      const params = {
        id,
        isDeviationStock: true
      };
      ApiService.query('productStockCheck/getProductStockCheckItems', {
        params,
      }).then(({ data }) => {
        const { detail: productStockCheck, items: products } = data.data
        this.inventoryCheckStatus = productStockCheck.status
        this.mainModel = {
          ...this.mainModel,
          products,
          code: productStockCheck.code,
          productStockCheckId: productStockCheck.id,
          prpoductStockCheckCode: productStockCheck.code,
          storeId: productStockCheck.storeId,
          storeName:
            productStockCheck.shortNameStore,
        };
      });
    },
    fetchStockDebt: function () {
      const productStockCheckId = this.mainModel.productStockCheckId;
      if (!productStockCheckId) {
        return;
      }
      ApiService.get(`/stockDebt/${productStockCheckId}`).then((response) => {
        const {
          stockDebtItems,
          cashAmount,
          cashAccountId,
          cashAccName,
          cashAccountCode,
          transferAmount,
          transferAccountId,
          transferAccName,
          transferAccountCode,
          transferReferenceCode,
          moneyTransfered,
          payments,
          moneyPaid,
        } = response.data.data;
        this.mainModel.stockDebtItems = stockDebtItems || [];
        this.mainModel.paymentInfo.cashAmount = cashAmount;
        this.mainModel.paymentInfo.cashAccountId = cashAccountId;
        this.mainModel.paymentInfo.cashAccountantName = cashAccName;
        this.mainModel.paymentInfo.cashAccountCode = cashAccountCode;
        this.mainModel.paymentInfo.transferAmount = transferAmount;
        this.mainModel.paymentInfo.transferAccountantId = transferAccountId;
        this.mainModel.paymentInfo.transferAccountantName = transferAccName;
        this.mainModel.paymentInfo.transferAccountCode = transferAccountCode;
        this.mainModel.paymentInfo.transferReferenceCode =
          transferReferenceCode;
        this.mainModel.moneyTransfered = moneyTransfered;
        this.mainModel.moneyPaid = moneyPaid;
        const transfer = payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
        );
        this.mainModel.transfer =
          transfer.length > 0 ? transfer : this.mainModel.transfer;

        const cash = payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.CASH,
        );
        this.mainModel.cash = cash.length > 0 ? cash : this.mainModel.cash;
        const totalPaid = sumBy(payments, 'paymentAmount')
        let totalMinusSalary = 0;
        if (stockDebtItems && stockDebtItems.length > 0) {
          totalMinusSalary = sumBy(stockDebtItems, 'paid')
        }
        this.totalAmountPaid = totalPaid + totalMinusSalary || 0;
      });
    },
    showDeleteAlert: function (stockDebtItem) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa nhân viên chịu truy thu này ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (stockDebtItem.timesPaid !== 0) {
          makeToastFaile('Đã phát sinh thanh toán không thể xóa !');
          return;
        }
        if (result.dismiss != 'cancel') {
          this.mainModel.stockDebtItems = this.mainModel.stockDebtItems.filter(
            (element) => {
              return element.id !== stockDebtItem.id;
            },
          );
          this.renderTable();
        }
      });
    },
    gettitleFinal() {
      if (this.remainAmount >= 0) {
        return 'Còn lại'
      } else {
        return 'Tiền thừa'
      }

    },
    calPaidAmount(stockDebtItem) {
      const paid = Number(stockDebtItem.paid) || 0;
      const paidAdd = Number(stockDebtItem.paidAdd) || 0
      return paid + paidAdd
    }
  },
  computed: {
    finalAmount() {
      const totalAmoutDebt = sumBy(this.mainModel.products, 'debt')
      const totalMinusSalary = sumBy(this.mainModel.stockDebtItems, 'totalAmount')
      return totalAmoutDebt - totalMinusSalary
    },
    getTotalAmountDebt() {
      return sumBy(this.mainModel.products, 'debt');
    },
    remainAmount() {
      const totalAmountDebt = sumBy(this.mainModel.products, 'debt')
      const totalMoneyPaid = this.calcPaymentsAmount(this.mainModel.cash) + this.calcPaymentsAmount(this.mainModel.transfer)
      const totalMinusSalary = sumBy(this.mainModel.stockDebtItems, 'paid');
      return totalAmountDebt - totalMoneyPaid - totalMinusSalary
    }
  },
};
</script>
<style scoped>
.text-input-size {
  font-size: 12px;
}

.thtable {
  font-weight: 600;
  color: #181c32;
  text-align: center;
}


.total {
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
}

.amount-input {
  height: calc(1.35rem + 1.1rem + 2px) !important;
  border: 1px solid #007bff !important;
  text-align: right !important;
}
</style>
