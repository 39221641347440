<template>
    <div>
        <b-modal ref="edit-inventory-stock-modal" title="Chỉnh sủa tồn thực tế" size="l" hide-footer>
            <b-card bg-variant="light" text-variant="dark" class="mb-5">
                <b-card-text>
                    <b-form>
                        <b-form-group label="Tồn thực:" label-for="debt">
                            <b-form-input v-model="mainModal.realStockQuantity" :state="validaterealStockQuantity"
                                id="debt"></b-form-input>
                            <b-form-invalid-feedback :state="validaterealStockQuantity">
                                Nhập tồn thực !
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Tình trạng:" label-for="note">
                            <b-form-select class="input-style" :options="PRODUCT_CHECK_STATUS" size="sm" value-field="value"
                                text-field="text" v-model="mainModal.status">
                            </b-form-select>
                            <b-form-invalid-feedback :state="validateNote">
                                Vui lòng chọn tình trạng !
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-form>
                </b-card-text>
            </b-card>
            <hr />
            <div class="d-flex justify-content-end">
                <b-button class="mr-3" @click="hideModal()">Hủy</b-button>
                <b-button variant="primary" @click="submitForm()">Lưu</b-button>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { PRODUCT_CHECK_STATUS } from '@/utils/constants';


export default {
    props: {
        productStockCheckItem: {
            type: Object
        }
    },
    data() {
        return {
            mainModal: {},
            PRODUCT_CHECK_STATUS
        };
    },
    watch: {
        $props: {
            handler(newProps) {
                this.mainModal = { ...newProps.productStockCheckItem }
            },
            deep: true,
            immediate: false,
        },
    },
    created() {

    },
    methods: {
        showModal() {
            this.$refs['edit-inventory-stock-modal'].show();
        },
        hideModal() {
            this.$refs['edit-inventory-stock-modal'].hide();
        },
        updateProductStockCheck() {
            const data = this.mainModal;
            ApiService.post('productStockCheckItem', data)
                .then((response) => {
                    const { message } = response.data;
                    makeToastSuccess(message);
                    this.$emit('fetchProductCheckStock')
                    this.hideModal()
                })
                .catch(({ response }) => {
                    makeToastFaile(response.data.message);
                });
        },
        submitForm() {
            if (!this.mainModal.realStockQuantity) {
                makeToastFaile('Vui lòng nhập chỉnh sửa!');
                return;
            }
            this.updateProductStockCheck()
        },

    },
    computed: {
        validateNote() {
            return !!this.mainModal.status
        },
        validaterealStockQuantity() {
            return !!this.mainModal.realStockQuantity
        }
    }
};
</script>
  
<style>
.input-style {
    border: 1px solid #ced4da;
}

.form-group label {
    font-weight: 500;
}
</style>
  